import React from "react";
import Slider from "react-slick";
import check from "../assets/img/check.png";
import item_bg from "../assets/img/roadmap-shape.png";
import uncheck from "../assets/img/uncheck.png";
import SectionHeader from "./SectionHeader";
const RoadMap = () => {
	const settings = {
		dots: false,
		infinite: false,
		slidesToShow: 4,
		autoplay: false,
		autoplaySpeed: 2000,
		arrows: false,
		pauseOnHover: true,
		draggable: true,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					autoplay: true,
					autoplaySpeed: 2000,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					autoplay: true,
					autoplaySpeed: 2000,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					autoplay: true,
					autoplaySpeed: 2000,
				},
			},
		],
	};
	return (
		<section className="roadmap-section" id="roadmap">
			<div className="container">
				<SectionHeader className="pb-lg-5" title="Roadmap" />
				<div className="roadmap-wrapper">
					<Slider {...settings}>
						<div>
							<div
								className="roadmap-item"
								style={{
									background: `url(${item_bg}) no-repeat center center / 100% 100%`,
								}}
							>
								<h3 className="title">Phase 1</h3>
								<ul>
									<li>
										<img src={check} alt="" />
										<span>Assemble Team</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Website V1.0</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Discord Community Building</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Animated Trailer V1.0</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>
											Genesis
											<span className="badge">Sold Out</span>
										</span>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<div
								className="roadmap-item"
								style={{
									background: `url(${item_bg}) no-repeat center center / 100% 100%`,
								}}
							>
								<h3 className="title">
									Phase 2 <small>(1H 2022) </small>
								</h3>
								<ul>
									<li>
										<img src={check} alt="" />
										<span>
											Main Collection
											<span className="badge">Sold Out</span>
										</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Whitepaper V1.0</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Public Mint on Magic Eden</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Listing on Marketplaces</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Kitsune DAO Governance</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Game Team Expansion</span>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<div
								className="roadmap-item"
								style={{
									background: `url(${item_bg}) no-repeat center center / 100% 100%`,
								}}
							>
								<h3 className="title">
									Phase 3 <small>(2H 2022) </small>
								</h3>
								<ul>
									<li>
										<img src={check} alt="" />
										<span>NFT Staking</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Website 2.0</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Cinematic Trailer</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>Game Demo</span>
									</li>
									<li>
										<img src={check} alt="" />
										<span>
											Haku Genesis collection
											<span className="badge">(Sold Out)</span>
										</span>
									</li>
									<li>
										<img src={uncheck} alt="" />
										<span>NFT breeding</span>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<div
								className="roadmap-item"
								style={{
									background: `url(${item_bg}) no-repeat center center / 100% 100%`,
								}}
							>
								<h3 className="title">
									Phase 4 <small>(2023)</small>
								</h3>
								<ul>
									<li>
										<img src={uncheck} alt="" />
										<span>
											Official launch of <br /> "The Defense of
											Foxtopia"
										</span>
									</li>
									<li>
										<img src={uncheck} alt="" />
										<span>
											Expansion of Foxtopia Fantasy World Map
										</span>
									</li>
									<li>
										<img src={uncheck} alt="" />
										<span>
											Unlocking of <br /> Hidden Foxes Lair
										</span>
									</li>
									<li>
										<img src={uncheck} alt="" />
										<span>Foxtopia Metaverse Implementation</span>
									</li>
								</ul>
							</div>
						</div>
					</Slider>
				</div>
			</div>
		</section>
	);
};

export default RoadMap;
