import React from "react";
import Slider from "react-slick";
import data from "../assets/data/data";
import nft_shapes from "../assets/img/nft-shape.png";
import useMobile from "../hooks/useMobile";
import CountdownWrapper from "./CountdownWrapper";
import NFTDesk from "./NFTDesk";
import SectionHeader from "./SectionHeader";

const NftCollection = ({ bgImage }) => {
	const { mobileScreen } = useMobile();

	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 5,
		slidesToScroll: 1,
		cssEase: "linear",
		autoplay: true,
		autoplaySpeed: 500,
		arrows: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};
	return (
		<section
			className="nft-collection overflow-hidden position-relative"
			id="nft"
		>
			<img className="nft-bg" src={nft_shapes} alt="" />
			<div className="container-fluid p-0">
				<div className="nft-container">
					<SectionHeader title="Haku Genesis collection" />
					<div className="nft-slide-wrapper">
						{mobileScreen ? (
							<Slider {...settings}>
								{data.nft__collections &&
									data.nft__collections.map(({ img }, i) => (
										<div className="nft-slide-item" key={i}>
											<img
												className="nft-slide-img"
												src={img}
												alt=""
											/>
										</div>
									))}
							</Slider>
						) : (
							<NFTDesk data={data.nft__collections} />
						)}
					</div>
				</div>
				<div className="container">
					<CountdownWrapper />
				</div>
			</div>
		</section>
	);
};

export default NftCollection;
