import React from "react";
import left from "../assets/img/left.png";
import right from "../assets/img/right.png";
const SectionHeader = ({ title, noShape, leftIcon, rightIcon }) => {
	return (
		<div className="d-flex justify-content-center">
			<div className={`section-header ${noShape ? " noShape" : ""}`}>
				<div className="arrow-img">
					<img src={leftIcon ? leftIcon : left} alt="" />
				</div>
				<h2 className="title">{title}</h2>
				<div className="arrow-img">
					<img src={rightIcon ? rightIcon : right} alt="" />
				</div>
			</div>
		</div>
	);
};

export default SectionHeader;
