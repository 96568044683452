import React from "react";

const TeamItem = ({ img, designation, name, info }) => {
	return (
		<div className="team__item">
			<div className="team__item-img">
				<img src={img} alt={name} />
			</div>
			<div className="team__item-content">
				<h3 className="name">{name}</h3>
				<span className="designation">{designation}</span>
				{info && <span className="info">{info}</span>}
			</div>
		</div>
	);
};

export default TeamItem;
