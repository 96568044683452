import React, { useState } from "react";
import data from "../assets/data/data";
import closer from "../assets/img/closer.png";
import logo from "../assets/img/logo.png";
import toggler from "../assets/img/toggler.png";
import useMobile from "../hooks/useMobile";
import "./Header.scss";
const Header = ({ modal }) => {
	const [toggleMenu, setToggleMenu] = useState(false);
	const { mobileScreen } = useMobile();
	return (
		<header className={`header-section ${modal ? "hide-header" : ""}`}>
			<div className="container">
				<div className="header-wrapper">
					<div
						className="header-bar d-inline-flex d-lg-none"
						onClick={() => setToggleMenu(!toggleMenu)}
					>
						{!toggleMenu ? (
							<img src={toggler} alt="img" />
						) : (
							<img src={closer} alt="img" />
						)}
					</div>
					<div className="logo">
						<a href="/">
							<img src={logo} alt="haku" />
						</a>
					</div>

					<div className={`menu-wrapper ${toggleMenu ? "active" : ""}`}>
						<ul className="menu">
							{/* {!mobileScreen && <li className=""></li>} */}
							{!mobileScreen && <li className="menu-pagination"></li>}
							{data.menu.map(({ name, link, target, sub }, i) => (
								<li key={i}>
									<a
										href={link}
										className="menu-link"
										onClick={() => setToggleMenu(false)}
									>
										{name}
									</a>
								</li>
							))}
							<li className="pe-lg-0">
								<a
									href="https://staking.foxtopia.io/"
									className="header-btn px-4"
									target="_blank"
									rel="noreferrer"
									onClick={() => setToggleMenu(false)}
								>
									<span>Staking</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
