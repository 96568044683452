import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import factions from "../assets/data/factions";
import faction_shape_2 from "../assets/img/banner-shapes.png";
import faction__shape from "../assets/img/factions/header-shape.png";
import left from "../assets/img/left-2.png";
import right from "../assets/img/right-2.png";
import SectionHeader from "./SectionHeader";

const Factions = ({ bgImage }) => {
	return (
		<section
			className="factions-section d-lg-flex flex-column overflow-hidden"
			id="fractions"
		>
			<div
				className="faction_shape_2"
				style={{
					background: `url(${faction_shape_2}) no-repeat center center / 100% 100%`,
				}}
			></div>
			<div
				className="faction-shape"
				style={{
					background: `url(${faction__shape}) no-repeat center bottom / cover`,
				}}
			></div>
			<div className="container-fluid">
				<SectionHeader
					noShape="no-shape"
					title="the factions"
					leftIcon={left}
					rightIcon={right}
				/>
			</div>
			<Tabs defaultIndex={2} className="flex-grow-1 d-lg-flex flex-column">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<TabList className="faction-nav pt-4">
								{factions &&
									factions.map(({ nav_img, nav_img_2 }, i) => (
										<Tab key={i}>
											<img src={nav_img} alt="" />
										</Tab>
									))}
							</TabList>
						</div>
					</div>
				</div>
				<div className="my-auto">
					<div className="container-fluid mt-3">
						{factions &&
							factions.map(
								(
									{
										circle_img,
										glow_img,
										main_img,
										title,
										subtitle,
										text,
									},
									i
								) => (
									<TabPanel key={i}>
										<div className={`factions-tab-content`}>
											<div className="sub-wrap">
												<ul>
													{Object.values(circle_img).map(
														({ rounded_img }, i) => (
															<li key={i}>
																<img src={rounded_img} alt="" />
															</li>
														)
													)}
												</ul>
											</div>
											<div className="main-wrap">
												<div className="thumb">
													<img
														src={glow_img}
														alt=""
														className="glow-image"
													/>
													<div className="main-image-item">
														<img
															src={main_img}
															alt=""
															className="main-image"
														/>
													</div>
												</div>
												<div className="content">
													<div className="cont">
														<h2 className="title">{title}</h2>
														<h5 className="subtitle">
															{subtitle}
														</h5>
														<p>{text}</p>
														<span>--------</span>
													</div>
												</div>
											</div>
										</div>
									</TabPanel>
								)
							)}
					</div>
				</div>
			</Tabs>
		</section>
	);
};

export default Factions;
