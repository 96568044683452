import React from "react";
import brand1 from "../assets/img/brand/1.png";
import brand2 from "../assets/img/brand/2.png";
import brand3 from "../assets/img/brand/3.png";
import SectionHeader from "./SectionHeader";

const Footer = () => {
	return (
		<>
			<footer id="partners" className="overflow-hidden">
				<div className="footer-wrapper">
					<div className="container">
						<SectionHeader title="Partners" />
						<div className="brand-wrapper">
							<div className="item">
								<a
									href="https://fractal.is/foxtopia"
									target="_blank"
									rel="noreferrer"
								>
									<img src={brand1} alt="brand" />
								</a>
							</div>
							<div className="item">
								<a
									href="https://catheongaming.com"
									target="_blank"
									rel="noreferrer"
								>
									<img src={brand2} alt="brand" />
								</a>
							</div>
							<div className="item">
								<a
									href="https://magiceden.io/marketplace/foxtopia"
									target="_blank"
									rel="noreferrer"
								>
									<img src={brand3} alt="brand" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="copyright text-center py-4">
					<span>&copy; Copyright 2022 Foxtopia NFT Collection</span>
				</div>
			</footer>
		</>
	);
};

export default Footer;
