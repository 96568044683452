import React, { useRef } from "react";
import Slider from "react-slick";
import background_image from "../assets/img/game-play-bg.png";
import img1 from "../assets/img/gameplay/1.png";
import img2 from "../assets/img/gameplay/2.png";
import img3 from "../assets/img/gameplay/3.png";
import img4 from "../assets/img/gameplay/4.png";
import next_img from "../assets/img/next.png";
import prev_img from "../assets/img/prev.png";
import SectionHeader from "./SectionHeader";

const game_play = [
	{
		img: img1,
		text: "Embark on quests to unlock unique and rare items to upgrade your character!",
	},
	{
		img: img3,
		text: "Have a taste of the early stages of our game production by playing our mini game demo.  ",
	},
	{
		img: img2,
		text: "Experiment with a range of defense and attack skills. ",
	},
	{
		img: img4,
		text: "Hack and slash those enemies away! ",
	},
];

const GamePlay = () => {
	const ref = useRef();
	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		lazyLoad: true,
		slidesToScroll: 1,
		pauseOnHover: false,
		pauseOnFocus: true,
	};

	const next = () => {
		ref.current.slickNext();
	};
	const previous = () => {
		ref.current.slickPrev();
	};
	return (
		<section
			className="gameplay-section"
			style={{
				background: `url(${background_image}) no-repeat center center / cover`,
			}}
			id="game"
		>
			<div className="container">
				<SectionHeader title="Gameplay" />
				<div className="gameplay-slider">
					<span className="prev-btn" onClick={previous}>
						<img src={prev_img} alt="" />
					</span>
					<Slider {...settings} ref={ref}>
						{game_play &&
							game_play.map(({ img, text }, i) => (
								<div className="slide-item" key={i}>
									<img src={img} alt="" />
									{text && <h4 className="subtitle">{text}</h4>}
								</div>
							))}
					</Slider>
					<span className="next-btn" onClick={next}>
						<img src={next_img} alt="" />
					</span>
					<h3 className="title">Join The Battle of Foxtopia!</h3>
					<p>
						Experience first-hand the early stages of our game production
						by participating in our mini game demo!
					</p>
					<a
						href="http://www.foxtopiagame.com"
						target="_blank"
						className="cmn--btn"
						rel="noreferrer"
					>
						<span>Try DEMO</span>
					</a>
				</div>
			</div>
		</section>
	);
};

export default GamePlay;
