import React, { useState } from "react";
import Slider from "react-slick";
import team from "../assets/data/team";
import useMobile from "../hooks/useMobile";
import TeamItem from "./TeamItem";
const Team = () => {
	const [index, setIndex] = useState(0);
	const { mobileScreen } = useMobile();
	const handleClick = (e) => {
		setIndex(e);
	};

	const settings = {
		dots: false,
		infinite: false,
		slidesToShow: 4,
		autoplay: false,
		autoplaySpeed: 2000,
		arrows: false,
		pauseOnHover: true,
		draggable: true,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					autoplay: true,
					infinite: true,
					autoplaySpeed: 2000,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					autoplay: true,
					infinite: true,
					autoplaySpeed: 2000,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					autoplay: true,
					infinite: true,
					autoplaySpeed: 2000,
				},
			},
		],
	};
	return (
		<section className="team-section overflow-hidden" id="team">
			<div className="container mb-auto pt-4 px-4">
				<ul className="tab-menu">
					<li
						className={index === 0 ? "active" : ""}
						onClick={() => handleClick(0)}
					>
						Core Team
					</li>
					<li
						className={index === 1 ? "active" : ""}
						onClick={() => handleClick(1)}
					>
						Dev Team
					</li>
				</ul>
			</div>
			<div className="container mb-auto">
				{mobileScreen ? (
					<>
						<div className={`team-slider ${index ? "" : "core-team"}`}>
							<Slider {...settings}>
								{team[index].team__data.map((item, i) => (
									<TeamItem {...item} key={i} />
								))}
							</Slider>
						</div>
					</>
				) : (
					<>
						<div
							className={`team-tab-content ${
								index === 0 ? "active" : ""
							}`}
						>
							<div className="row g-4 justify-content-center">
								{team[index].team__data.map((item, i) => (
									<div className="col-sm-6 col-md-3" key={i}>
										<TeamItem {...item} />
									</div>
								))}
							</div>
						</div>
						<div
							className={`team-tab-content dev-team ${
								index === 1 ? "active" : ""
							}`}
						>
							<div className="row g-2 g-xxl-4 justify-content-center">
								{team[index].team__data.map((item, i) => (
									<div className="col-sm-6 col-md-3" key={i}>
										<TeamItem {...item} />
									</div>
								))}
							</div>
						</div>
					</>
				)}
			</div>
		</section>
	);
};

export default Team;
