import React from "react";

const NFTDesk = ({ data }) => {
	return (
		<div className="nft-desk">
			<div className="nft-desk-inner">
				{data.map(({ img }, i) => (
					<div className="nft-slide-item" key={i}>
						<img className="nft-slide-img" src={img} alt="" />
					</div>
				))}
			</div>
		</div>
	);
};

export default NFTDesk;
