import React from "react";
import Countdown from "react-countdown";

import logo1 from "../assets/img/launch/1.png";
import logo2 from "../assets/img/launch/2.png";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	if (completed) {
		// return <h2>Website is already launch</h2>;
		return;
	} else {
		return (
			<div className="d-flex justify-content-center">
				<span className="countdown">
					<span className="item">
						<span className="subtitle">
							{days < 10 ? `0${days}` : days}
						</span>
						<span>Days</span>
					</span>
					<span className="item">
						<span className="subtitle">
							{hours < 10 ? `0${hours}` : hours}
						</span>
						<span>Hrs</span>
					</span>
					<span className="item">
						<span className="subtitle">
							{minutes < 10 ? `0${minutes}` : minutes}
						</span>
						<span>Min</span>
					</span>
					<span className="item">
						<span className="subtitle">
							{seconds < 10 ? `0${seconds}` : seconds}
						</span>
						<span>Sec</span>
					</span>
				</span>
			</div>
		);
	}
};
const CountdownWrapper = () => {
	const convertDate = (date, tzString) => {
		return new Date(
			(typeof date === "string" ? new Date(date) : date).toLocaleString(
				"en-US",
				{ timeZone: tzString }
			)
		);
	};
	return (
		<section className="countdown-section pt-4 pt-xxl-5">
			<div className="container">
				<div className="section-title">
					<h2 className="mb-0 title">Check it out now!</h2>
					<h3 className="mb-4 py-lg-2">Collections available in:</h3>
				</div>
				<Countdown
					date={convertDate(
						"September 28, 2022 11:00:00",
						"Asia/singapore"
					)}
					renderer={renderer}
				/>
				<div className="text-center mt-xxl-4 mt-2 pt-2">
					{/* <a
						href="https://forms.gle/bTsexgcXAbwavNAm7"
						className="discord mt-0 mb-4"
						target="_blank"
						rel="noreferrer"
					>
						<span>Get whitelisted</span>
					</a> */}
					{/* <a
						href="https://www.fractal.is/foxtopia/mints"
						className="discord mt-0 mb-4"
					>
						<span>Mint Now!</span>
					</a> */}
					<div className="d-flex justify-content-center flex--logos">
						<a
							href="https://www.fractal.is/foxtopia/items"
							target="_blank"
							rel="noreferrer"
						>
							<img src={logo1} alt="" />
						</a>
						<a
							href="https://magiceden.io/marketplace/haku"
							target="_blank"
							rel="noreferrer"
						>
							<img src={logo2} alt="" />
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CountdownWrapper;
