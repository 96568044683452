import React, { useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "./App.scss";
import close_icon from "./assets/img/close_icon.png";
import discord from "./assets/img/discord.png";
import twitter from "./assets/img/twitter.png";
import Banner from "./components/Banner";
import Factions from "./components/Factions";
import Footer from "./components/Footer";
import GamePlay from "./components/GamePlay";
import Header from "./components/Header";
import NftCollection from "./components/NftCollection";
import OurGame from "./components/OurGame";
import RoadMap from "./components/Roadmap";

import { Mousewheel, Pagination } from "swiper";
import Team from "./components/Team";
import useMobile from "./hooks/useMobile";
function App() {
	const onPlayerReady: YouTubeProps["onReady"] = (event) => {
		event.target.playVideo();
	};

	const opts: YouTubeProps["opts"] = {
		playerVars: {
			autoplay: 1,
		},
	};
	const [modal, setModal] = useState(false);

	const { mobileScreen } = useMobile();

	return (
		<>
			<Header modal={modal} />
			<div className="banner-social">
				<a
					href="http://discord.gg/foxtopia"
					target="_blank"
					rel="noreferrer"
				>
					<img src={discord} alt="" />
				</a>
				<a
					href="https://twitter.com/FoxtopiaNFT"
					target="_blank"
					rel="noreferrer"
				>
					<img src={twitter} alt="" />
				</a>
			</div>
			{mobileScreen ? (
				<>
					<div className="mobile-screen">
						<Banner setModal={setModal} modal={modal} />
						<OurGame />
						<Factions />
						<GamePlay />
						<NftCollection />
						<RoadMap />
						<Team />
						<Footer />
					</div>
				</>
			) : (
				<Swiper
					direction={"vertical"}
					slidesPerView={1}
					spaceBetween={0}
					speed={1500}
					mousewheel={{
						forceToAxis: false,
					}}
					pagination={{ clickable: true, el: ".menu-pagination" }}
					modules={[Pagination, Mousewheel]}
					className=""
				>
					<SwiperSlide>
						<Banner setModal={setModal} modal={modal} />
					</SwiperSlide>
					<SwiperSlide>
						<OurGame />
					</SwiperSlide>
					<SwiperSlide>
						<Factions />
					</SwiperSlide>
					<SwiperSlide>
						<GamePlay />
					</SwiperSlide>
					<SwiperSlide>
						<NftCollection />
					</SwiperSlide>
					<SwiperSlide>
						<RoadMap />
					</SwiperSlide>
					<SwiperSlide>
						<Team />
					</SwiperSlide>
					<SwiperSlide>
						<Footer />
					</SwiperSlide>
				</Swiper>
			)}
			{/* banner modal video */}
			{modal && (
				<div
					className="video-modal-wrapper"
					onClick={() => setModal(false)}
				>
					<div className="close__video">
						<img src={close_icon} alt="" />
					</div>
					<div className="inner" onClick={() => setModal(true)}>
						<YouTube
							videoId="lzbysA5-jW0"
							opts={opts}
							onReady={onPlayerReady}
						/>
					</div>
				</div>
			)}
			{/* banner modal video */}
		</>
	);
}

export default App;
