import React from "react";
import banner_bg from "../assets/img/banner-bg.png";
import banner_shapes from "../assets/img/banner-shapes.png";
import banner_shapes_2 from "../assets/img/mobile-banner-shapes.png";
import play__icon from "../assets/img/play-btn.png";
import bannerVideo from "../assets/video/Wip_Foxtopia_01.mp4";
import useMobile from "../hooks/useMobile";
import "./Banner.scss";
const Banner = ({ modal, setModal }) => {
	const { mobileScreen } = useMobile();
	return (
		<>
			<div
				className="banner-section"
				style={{
					background: `url(${banner_bg}) no-repeat center center / cover`,
				}}
			>
				<img
					className="banner-bg"
					src={mobileScreen ? banner_shapes_2 : banner_shapes}
					alt=""
				/>
				<video className="banner-video" autoPlay loop muted>
					<source src={bannerVideo} type="video/mp4" />
				</video>
				<div className="banner-content">
					<h2 className="title">
						Foxtopia is a Free-to-Play and Play to-Earn
					</h2>
					<p>Combat style action RPG built on the Solana Blockchain.</p>
					<div className="play-icon" onClick={() => setModal(!modal)}>
						<img src={play__icon} alt="" />
					</div>
				</div>
			</div>
		</>
	);
};

export default Banner;
